import type { Testimony } from "@/modules/corporate/types/testimonies"
import type { FunctionComponent } from "react"

import Image from "next/image"

import { Paper, PAPER_RADIUS } from "@jobteaser/ui-kit/components/Paper/Paper"
import { Text, TEXT_COLORS, TEXT_TYPES } from "@jobteaser/ui-kit/components/Text/Text"

import quote from "@/modules/corporate/images/quote.svg"
import { RichText } from "@/modules/kontent/components/RichText"
import { getAssetImportUrl } from "@/modules/staticAssets/getAssetImportUrl"

import styles from "./Testimony.module.css"

type TestimonyItemProps = {
  testimony: Testimony
}

export const TestimonyItem: FunctionComponent<TestimonyItemProps> = ({ testimony }) => (
  <div className={styles.wrapper} key={JSON.stringify(testimony.description)}>
    {/* TODO Update Paper component to accept key, style or other attribute */}
    <Paper className={styles.card} radius={PAPER_RADIUS.DEFAULT} hasBorder noShadow>
      {!!testimony.companyImage.length && (
        <Image
          src={testimony.companyImage[0].url}
          alt={testimony.companyImage[0].description || testimony.companyName}
          className={styles.image}
          height={testimony.companyImage[0].height || undefined}
          width={testimony.companyImage[0].width || undefined}
          sizes="50vw"
        />
      )}
      <RichText
        value={testimony.description}
        wrapperProps={{
          className: styles.text,
          resetSpacing: true,
          tag: "div",
        }}
      />
      {testimony.authorName && (
        <footer
          className={styles.signature}
          style={{ "--backgroundImage": `url(${getAssetImportUrl(quote)})` } as React.CSSProperties}
        >
          {!!testimony.authorImage.length && (
            <picture className={styles.author}>
              <Image
                className={styles.authorImage}
                src={testimony.authorImage[0].url}
                width={testimony.authorImage[0].width || undefined}
                height={testimony.authorImage[0].height || undefined}
                alt={testimony.authorImage[0].description || ""}
              />
            </picture>
          )}
          <div>
            <Text resetSpacing type={TEXT_TYPES.NORMAL}>
              {testimony.authorName}
            </Text>
            {testimony.authorJob && (
              <Text type={TEXT_TYPES.SMALL} resetSpacing color={TEXT_COLORS.GREY}>
                {testimony.authorJob}
              </Text>
            )}
          </div>
        </footer>
      )}
    </Paper>
  </div>
)
