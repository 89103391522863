import type { TestimonySection } from "@/modules/corporate/types/sections"
import type { FunctionComponent } from "react"

import { Carousel } from "@jobteaser/ui-kit/components/Carousel/Carousel"
import { Layout } from "@jobteaser/ui-kit/components/Layout/Layout"

import { Title } from "@/modules/corporate/components/Sections/Title/Title"

import { TestimonyItem } from "./TestimonyItem"

import styles from "./Testimony.module.css"

export type TestimonyPropsType = {
  section: TestimonySection
}

export const Testimony: FunctionComponent<TestimonyPropsType> = ({ section }) => (
  <Layout className={styles.main} hasTopPadding hasBottomPadding>
    <Layout.Column>
      <Carousel>
        <Carousel.Header>
          <Title title={section.title} tag="h2" isCentered />
        </Carousel.Header>
        <Carousel.Content>
          {section.testimonies.map(testimony => (
            <Carousel.Item key={testimony.companyName}>
              <TestimonyItem testimony={testimony} />
            </Carousel.Item>
          ))}
        </Carousel.Content>
      </Carousel>
    </Layout.Column>
  </Layout>
)
